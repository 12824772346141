<template>
    <section class="documents-management main-body">
        <v-tabs
            v-model="active"
            class="full-width"
            full-width
        >
            <v-tab :ripple="{ class: 'accent--text' }">Content</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <div class="top-bar mx-2">
                    <v-flex>
                        <v-text-field v-model="fileName"></v-text-field>
                    </v-flex>
                </div>
                <div class="mx-2 document-edit-wrapper">

                    <div class="sidebar">
                        <div class="sidebar-header">
                            <v-btn
                                flat
                                small
                                class="my-0"
                                :class="{ 'v-btn--active': activeHeading == 'h1' }"
                                :active="activeHeading == 'h1'"
                                @click="activeHeading = 'h1'"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                >
                                    <path
                                        d="M23.75 22.5V12.5L21.25 15M5 7.5V22.5M15 7.5V22.5M13.75 22.5H16.25M3.75 22.5H6.25M5 15H15M3.75 7.5H6.25M13.75 7.5H16.25"
                                        stroke="white"
                                        stroke-width="2.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </v-btn>
                            <v-btn
                                class="my-0"
                                :class="{ 'v-btn--active': activeHeading == 'h2' }"
                                flat
                                small
                                :active="activeHeading == 'h2'"
                                @click="activeHeading = 'h2'"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M17 12C17 11.4696 17.2107 10.9609 17.5858 10.5858C17.9609 10.2107 18.4696 10 19 10C19.5304 10 20.0391 10.2107 20.4142 10.5858C20.7893 10.9609 21 11.4696 21 12C21 12.591 20.583 13.318 20.184 13.858L17 18.001H21M4 6V18M12 6V18M11 18H13M3 18H5M4 12H12M3 6H5M11 6H13"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                            </v-btn>
                            <v-btn
                                class="my-0"
                                :class="{ 'v-btn--active': activeHeading == 'h3' }"
                                flat
                                small
                                :active="activeHeading == 'h3'"
                                @click="activeHeading = 'h3'"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                >
                                    <path
                                        d="M12.75 9C12.75 8.70333 12.838 8.41332 13.0028 8.16664C13.1676 7.91997 13.4019 7.72771 13.676 7.61418C13.9501 7.50065 14.2517 7.47094 14.5426 7.52882C14.8336 7.5867 15.1009 7.72956 15.3107 7.93934C15.5204 8.14912 15.6633 8.41639 15.7212 8.70736C15.7791 8.99834 15.7494 9.29994 15.6358 9.57402C15.5223 9.84811 15.33 10.0824 15.0834 10.2472C14.8367 10.412 14.5467 10.5 14.25 10.5C14.5467 10.5 14.8367 10.588 15.0834 10.7528C15.33 10.9176 15.5223 11.1519 15.6358 11.426C15.7494 11.7001 15.7791 12.0017 15.7212 12.2926C15.6633 12.5836 15.5204 12.8509 15.3107 13.0607C15.1009 13.2704 14.8336 13.4133 14.5426 13.4712C14.2517 13.5291 13.9501 13.4994 13.676 13.3858C13.4019 13.2723 13.1676 13.08 13.0028 12.8334C12.838 12.5867 12.75 12.2967 12.75 12M3 4.5V13.5M9 4.5V13.5M8.25 13.5H9.75M2.25 13.5H3.75M3 9H9M2.25 4.5H3.75M8.25 4.5H9.75"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </v-btn>
                            <v-btn
                                class="my-0"
                                :class="{ 'v-btn--active': activeHeading == 'h4' }"
                                flat
                                small
                                :active="activeHeading == 'h4'"
                                @click="activeHeading = 'h4'"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        d="M13.3333 12V6.66667L10.6667 10.6667H14M2.66667 4V12M8 4V12M7.33333 12H8.66667M2 12H3.33333M2.66667 8H8M2 4H3.33333M7.33333 4H8.66667"
                                        stroke="white"
                                        stroke-width="1.33333"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </v-btn>
                        </div>
                        <div class="px-4">

                            <v-text-field
                                v-model="searchHeading"
                                placeholder="Search"
                                clearable
                            ></v-text-field>
                            <template v-if="activeHeading === 'h1'">

                                <v-expansion-panel
                                    expand
                                    v-model="panels"
                                    class="accordion"
                                >
                                    <v-expansion-panel-content
                                        v-for="(heading, index) in filteredArray"
                                        :key="index"
                                        :aria-labelledby="heading.id"
                                        :class="{ 'heading--active': activeHeadingIndex == index }"
                                    >
                                        <div
                                            class="accordion__title"
                                            slot="header"
                                            @click="onHeadingClick(heading.id, index)"
                                        >{{ heading.text }}</div>

                                        <ul>
                                            <li
                                                v-for="(subheading, i) in getSubheadings(heading.text)"
                                                :key="i"
                                            >
                                                {{ subheading }}
                                            </li>
                                        </ul>
                                    </v-expansion-panel-content>

                                </v-expansion-panel>
                            </template>
                            <template v-else>
                                <ul>

                                    <li
                                        v-for="(heading, index) in filteredArray"
                                        :key="index"
                                        class="sidebar-heading"
                                        :class="{ 'sidebar-heading--active': activeHeadingIndex == index }"
                                    ><a
                                            href="#"
                                            @click.prevent="onHeadingClick(heading.id, index)"
                                        >{{ heading.text }}</a></li>

                                </ul>
                            </template>

                        </div>
                    </div>
                    <div class="content-right">

                        <v-layout
                            class="content-right-wrapper"
                            row
                        >
                            <v-flex
                                no-gutters
                                class="col-textarea"
                            >
                                <input
                                    id="uploadfile"
                                    type="file"
                                    accept=".md"
                                    @change="onFileChanged"
                                >

                                <tinymce-editor
                                    ref="tiny"
                                    v-model="htmlInput"
                                    api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
                                    :init="{
                                        selector: 'textarea',
                                        plugins: 'textpattern lists advlist link table image',
                                        advlist_bullet_styles: 'square',
                                        advlist_number_styles: 'default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman',
                                        height: '500px',
                                        menubar: false,
                                        forced_root_blocks: false,
                                        force_br_newlines: true,
                                        convert_newlines_to_brs: true,
                                        toolbar: 'undo redo | styleselect | bold italic underline| blockquote table | bullist numlist| link image customInsertButton',
                                        textpattern_patterns: [
                                            { start: '#', format: 'h1' },
                                            { start: '##', format: 'h2' },
                                            { start: '###', format: 'h3' },
                                            { start: '####', format: 'h4' },
                                            { start: '#####', format: 'h5' },
                                            { start: '######', format: 'h6' },
                                            { start: '* ', cmd: 'InsertUnorderedList' },
                                            { start: '- ', cmd: 'InsertUnorderedList' },
                                            { start: '1. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'decimal' } },
                                            { start: '1) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'decimal' } },
                                            { start: 'a. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-alpha' } },
                                            { start: 'a) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-alpha' } },
                                            { start: 'i. ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-roman' } },
                                            { start: 'i) ', cmd: 'InsertOrderedList', value: { 'list-style-type': 'lower-roman' } }

                                        ],
                                        setup: function (editor) {
                                            editor.ui.registry.addButton('customInsertButton', {
                                                text: '',
                                                onAction: function (_) {
                                                    openModal()
                                                }
                                            });
                                        },
                                    }"
                                >
                                </tinymce-editor>
                            </v-flex>
                        </v-layout>
                        <v-dialog
                            ref="consentModal"
                            v-model="consentModal"
                            width="600px"
                            height="600px"
                        >
                            <v-card>
                                <v-card-title class="card-title">Before you import a file...</v-card-title>
                                <v-card-text>
                                    <p>Please note, all files must be uploaded in the File.MD format.</p>

                                    <p>Please note, all files must be uploaded in the File.MD format. For more information on how to convert a
                                        file to File.MD, <a href="https://www.markdownguide.org/getting-started/#documents">CLICK HERE</a></p>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        outline
                                        flat
                                        @click="consentModal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="accent"
                                        outline
                                        flat
                                        @click="consentModal = false, openFile()"
                                    >
                                        Ok, I understand </v-btn>

                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>


                </div>

            </v-tab-item>
        </v-tabs>
        <sticky-footer
            :errorMessage="errorMessage"
            :status="saveStatus"
            @buttonClicked="isExistingForm ? saveDoc() : addDoc()"
            :allowPreview="false"
        />
    </section>
</template>

<script >
import StickyFooter from "../Library/StickyFooter.vue";

import { useDocuments } from "../../composables/useDocuments.ts"
const { postDocument, error, getDocument, patchDocument, currentDocument } = useDocuments();
import { split, startsWith, trim } from "lodash"
import Editor from "@tinymce/tinymce-vue";
import showdown from "showdown"

const _split = split
export default {
    name: "DocumentEdit",
    components: {
        StickyFooter,
        "tinymce-editor": Editor,
    },
    data() {
        return {
            active: null,
            isExistingForm: false,
            errorMessage: "",
            saveStatus: "saved",
            markdownInput: "",
            componentKey: 0,
            fileName: "",
            htmlInput: "",
            currentId: "",
            searchHeading: "",
            activeHeading: "h1",
            h1Array: [],
            h2Array: [],
            h3Array: [],
            h4Array: [],
            consentModal: false,
            postingError: error,
            panels: null,
            activeHeadingIndex: 0
        };
    },
    created() {

        if (this.$attrs.id.length > 0 && this.$attrs.id !== "create") {
            this.isExistingForm = true
            this.currentId = this.$attrs.id
            this.getData()
        }
        this.saveStatus = "saved"
        setTimeout(() => {
            this.setAllheadings()
        }, 1000)

    },
    watch: {
        htmlInput: function (newVal, oldValue) {

            if (oldValue !== newVal) {
                this.convertToMarkdown()

                setTimeout(() => {
                    this.setAllheadings()
                }, 1000)
                this.saveStatus = "needsSaving"
            }
        },
        filename: function (newVal) {
            if (newVal) {
                this.convertToMarkdown()
                this.saveStatus = "needsSaving"
            }
        }
    },
    computed: {
        activeHeadingArray() {
            let activeArray = []
            switch (this.activeHeading) {
                case "h1":
                    activeArray = this.h1Array
                    break;
                case "h2":
                    activeArray = this.h2Array
                    break;
                case "h3":
                    activeArray = this.h3Array
                    break;
                case "h4":
                    activeArray = this.h4Array
                    break;
                default:
                    activeArray = this.h1Array
                    break;
            }
            return activeArray
        },
        filteredArray() {
            const that = this
            if (that.searchHeading.length > 0) {
                return that.activeHeadingArray.filter((item) => item.id.includes(that.searchHeading.toLowerCase()))
            } else {
                return that.activeHeadingArray
            }
        },
        h1Positions() {
            const headingArr = _split(this.markdownInput, "\n")
            const positionH1array = []
            if (headingArr) {
                headingArr.forEach((item, i) => {

                    if (startsWith(item, "# ")) {
                        positionH1array.push(i)
                    }

                })
            }
            return positionH1array
        },
        htmlRendered() {
            let newText = this.htmlInput
            if (newText.length && this.htmlInput.match(/^<li>[a|A|i|I]\.<\/li>/)) {
                newText = newText.replace(/^<li>(?<type>.)\.<\/li>(?<list>.*)/gms, `<ol type="$<type>" start="${start}">$<list></ol>`);
            }
            return newText
        }
    },
    methods: {
        setAllheadings() {
            this.h1Array = this.setHeadings("h1")
            this.h2Array = this.setHeadings("h2")
            this.h3Array = this.setHeadings("h3")
            this.h4Array = this.setHeadings("h4")
        },

        setHeadings(headingType) {
            const iframe = document.getElementsByClassName("tox-edit-area__iframe")
            const headingsArray = []
            if (iframe[0] !== undefined) {

                const headingsCollection = iframe[0].contentWindow.document.getElementsByTagName(headingType)
                Array.from(headingsCollection).forEach(element => {

                    headingsArray.push(
                        {
                            id: element.id,
                            text: element.innerText
                        })
                })
            }
            return headingsArray !== null ? headingsArray : []
        },

        getSubheadings(heading) {

            const headingArr = _split(this.markdownInput, "\n")
            const currentIndex = headingArr.findIndex((item) => item.includes(heading))
            const subset = []
            const headingIndex = this.h1Positions.indexOf(currentIndex)

            headingArr.forEach((item, j) => {
                if (j > currentIndex && j < this.h1Positions[headingIndex + 1]) {
                    if (startsWith(item, "## ")) subset.push(trim(item, "## "))
                }
                if (headingIndex == this.h1Positions.length - 1 && j > currentIndex) {
                    if (startsWith(item, "## ")) subset.push(trim(item, "## "))
                }
            })
            return subset
        },

        async saveDoc() {
            await patchDocument(this.currentId, this.fileName, this.markdownInput)
            this.saveStatus = "saved"
        },

        async addDoc() {
            await postDocument(this.fileName, this.markdownInput)
            this.saveStatus = "saved"
        },

        async getData() {
            await getDocument(this.currentId)
            this.markdownInput = currentDocument.value.data.data.attributes.documentContents
            this.fileName = currentDocument.value.data.data.attributes.documentTitle
            this.convertToHtml()
        },

        changeMarkdown(args) {
            this.markdownInput = args.text
            this.fileName = args.filename
            this.convertToHtml()
            this.componentKey++
        },

        convertToHtml() {
            const converter = new showdown.Converter({
                splitAdjacentBlockquotes: true, tables: true, tasklists: true,
                disableForced4SpacesIndentedSublists: true, smartIndentationFix: true, simpleLineBreaks: true
            })
            this.htmlInput = converter.makeHtml(this.markdownInput);

        },

        convertToMarkdown() {
            const converter = new showdown.Converter()
            this.markdownInput = converter.makeMd(this.htmlInput);

        },
        parseListsHtml() {
            let newText = this.htmlInput
            if (newText.length && this.htmlInput.match(/^<li>[a|A|i|I]\.<\/li>/)) {
                newText = newText.replace(/^<li>(?<type>.)\.<\/li>(?<list>.*)/gms, `<ol type="$<type>" start="${start}">$<list></ol>`);
            }
            return newText
        },

        async onFileChanged($event) {
            const file = $event.target.files[0]
            const text = await file.text();
            const filename = await file.name
            this.changeMarkdown({ filename: filename, text: text })
        },

        openFile() {
            document.getElementById("uploadfile").click();
        },
        openModal() {
            this.consentModal = true
        },
        onHeadingClick(heading, index) {
            this.activeHeadingIndex = index
            const iframe = document.getElementsByClassName("tox-edit-area__iframe")
            const elementToScroll = iframe[0].contentWindow.document.getElementById(heading)
            if (elementToScroll) elementToScroll.scrollIntoView({
                behavior: 'smooth'
            });
        }

    },

}
</script>
<style lang="scss">
.tox .tox-editor-header {
    .tox-toolbar__primary {
        background: var(--Grey-Flat, #A8AAB7);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        padding: 8px 16px;
        align-items: center;
        margin-bottom: 30px;
        color: white;
    }

    .tox-tbtn:not(.tox-tbtn--disabled) {
        svg {
            fill: white;
        }

        color: white;
    }
}

.tox .tox-toolbar__group {
    &:last-of-type {
        .tox-tbtn--select {
            min-width: 30px;
            right: 30px;
            position: absolute;
            cursor: pointer;
            background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" ><rect width="30" height="30" rx="1" fill="%23516173" /><path fill-rule="evenodd" clip-rule="evenodd" d="M18.78 14.159C18.6394 14.0185 18.4488 13.9397 18.25 13.9397C18.0512 13.9397 17.8606 14.0185 17.72 14.159L15.75 16.129V8.75C15.75 8.55109 15.671 8.36032 15.5303 8.21967C15.3897 8.07902 15.1989 8 15 8C14.8011 8 14.6103 8.07902 14.4697 8.21967C14.329 8.36032 14.25 8.55109 14.25 8.75V16.129L12.28 14.159C12.1378 14.0265 11.9498 13.9544 11.7555 13.9578C11.5612 13.9613 11.3758 14.04 11.2384 14.1774C11.101 14.3148 11.0223 14.5002 11.0188 14.6945C11.0154 14.8888 11.0875 15.0768 11.22 15.219L14.47 18.469L15 19L15.53 18.47L18.78 15.22C18.8497 15.1504 18.905 15.0676 18.9427 14.9766C18.9804 14.8856 18.9998 14.788 18.9998 14.6895C18.9998 14.591 18.9804 14.4934 18.9427 14.4024C18.905 14.3114 18.8497 14.2287 18.78 14.159ZM9.5 16.75C9.5 16.6515 9.4806 16.554 9.44291 16.463C9.40522 16.372 9.34997 16.2893 9.28033 16.2197C9.21069 16.15 9.12801 16.0948 9.03701 16.0571C8.94602 16.0194 8.84849 16 8.75 16C8.65151 16 8.55398 16.0194 8.46299 16.0571C8.37199 16.0948 8.28931 16.15 8.21967 16.2197C8.15003 16.2893 8.09478 16.372 8.05709 16.463C8.0194 16.554 8 16.6515 8 16.75V20C8 20.5304 8.21071 21.0391 8.58579 21.4142C8.96086 21.7893 9.46957 22 10 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V16.75C22 16.5511 21.921 16.3603 21.7803 16.2197C21.6397 16.079 21.4489 16 21.25 16C21.0511 16 20.8603 16.079 20.7197 16.2197C20.579 16.3603 20.5 16.5511 20.5 16.75V20C20.5 20.1326 20.4473 20.2598 20.3536 20.3536C20.2598 20.4473 20.1326 20.5 20 20.5H10C9.86739 20.5 9.74021 20.4473 9.64645 20.3536C9.55268 20.2598 9.5 20.1326 9.5 20V16.75Z" fill="white" /></svg>')
        }
    }
}

.heading--active {

    .v-expansion-panel__header {
        background: var(--Grey-Flat, #A8AAB7);
        color: white;
    }
}
</style>
<style scoped lang='scss'>
.v-tabs.full-width {
    width: 100%;
}

.top-bar {
    width: 100%;
    display: flex;

    .v-input {
        width: 30%;
    }
}

.document-edit-wrapper {
    display: flex;
    width: 100%;
}

input#uploadfile {
    display: none;
}

.main-body {
    display: flex;
    flex-direction: column;
}

.content-right-wrapper {
    padding-left: 20px;
}

.col-textarea {
    flex-shrink: 0;

}

.sidebar {
    max-width: 30%;
    background: var(--Grey-Base, #F9F9F9);
    box-shadow: 0px 16px 6px 6px rgba(168, 170, 183, 0.15);
    flex: 1 1 auto;

    ul {
        padding-bottom: 14px;

        li:not(.v-expansion-panel__container):not(.sidebar-heading) {
            padding: 14px 0 0px 30px;
        }

    }

    .v-btn {
        min-width: 20px;
    }


    &-heading {
        padding: 4px;
        transition: all .2s;

        a {
            color: #516173;
            font-size: 16px;
            text-decoration: none;
        }

        &:hover {
            background-color: #A8AAB7;
        }

        &.sidebar-heading--active {
            background-color: #A8AAB7;
        }
    }
}

.content-right {
    position: relative;
    flex: 1 1 auto;
}

.sidebar-header {
    background: var(--Grey-Dark, #516173);
    box-shadow: 0px 4px 4px 0px rgba(170, 170, 170, 0.10);
    justify-content: flex-start;
    display: flex;
    padding: 14px 16px;
}

.bg-white {
    background: white;
}
</style>
