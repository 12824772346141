import {getDocuments, postDocumentModel, updateDocument, deleteDocumentModel, getDocumentModel} from "../api/documents"
import { ref, Ref } from "vue"
import {DocumentApiModel, DocumentBody, SingleDocumentFromApi, EditDocumentBody} from "../types/documents"

export function useDocuments() {
    const documentsModel: Ref<DocumentApiModel[]> = ref([])
    const currentDocument: Ref<null|SingleDocumentFromApi> = ref(null)
    const error = ref("")
    const loading = ref(false)
    const postedSuccesful = ref(false)
    const deletedSuccesful = ref(false)
    const total = ref(0)
    const fetchDocuments = async (pageSize?: number, pageNumber?: number, sort?:string) => {
        loading.value = true
        const apiresponse = await getDocuments(pageSize, pageNumber, sort)
        if (apiresponse){
            error.value = ""
            documentsModel.value = apiresponse.data.data

            if (apiresponse.data && apiresponse.data.meta) total.value = apiresponse.data.meta.totalCount

            loading.value= false
        } else {
            error.value = "There was an error retrieving items"
            loading.value= false
        }
    }

    const patchDocument = async(id:string,title: string, contents: string)=>{
        const body:EditDocumentBody= {
            type: 'document',
            id: id,
            attributes: {
                deleted:null,
                documentTitle: title,
                documentContents: contents
            }
        }
        const apiresponse = await updateDocument(id,body)
        if (apiresponse){
            currentDocument.value = apiresponse
        }
    }

    const postDocument = async (title: string, contents: string) => {
        error.value = ""
        const body:DocumentBody= {
                type: 'document',
                attributes: {
                    documentTitle: title,
                    documentContents: contents
                }
        }
        const apiresponse = await postDocumentModel(body)
        if (apiresponse){
            postedSuccesful.value = true
        } else {
            postedSuccesful.value = false
            error.value = "There was a problem posting the document"
        }
    }

    const deleteDocument = async(id: string)=>{
        const apiresponse = await deleteDocumentModel(id)
        if (apiresponse){
            deletedSuccesful.value = true
        }else {
            deletedSuccesful.value = false
            error.value = "There was a problem deleting the document"
        }
    }

    const getDocument = async(id: string)=>{
        const apiresponse = await getDocumentModel(id)
        if (apiresponse){
            currentDocument.value = apiresponse
        }
    }


    return {
        fetchDocuments,
        documentsModel,
        document,
        postDocument,
        deleteDocument,
        currentDocument,
        getDocument,
        patchDocument,
        error,
        postedSuccesful,
        deletedSuccesful,
        total
    }
}
