import { getService } from "./service.ts"
import {DocumentBody} from "../types/documents.ts"
export async function getDocuments(pageSize?: number, pageNumber?: number, sort?: string) {

    if(!pageSize && !pageNumber && !sort){
        return await getService(`${process.env.VUE_APP_DOCUMENTSAPI}/documents`)
    } else return await getService(`${process.env.VUE_APP_DOCUMENTSAPI}/documents?page.number=${pageNumber}&page.size=${pageSize}`)
}

export async function getDocumentModel(documentId: string) {
    return getService(`${process.env.VUE_APP_DOCUMENTSAPI}/documents/${documentId}`)
}

export async function postDocumentModel(docBody: DocumentBody) {
    return getService(`${process.env.VUE_APP_DOCUMENTSAPI}/documents`,'POST',{data: docBody})
}

export async function updateDocument(documentId: string,docBody: DocumentBody) {
    return getService(`${process.env.VUE_APP_DOCUMENTSAPI}/documents/${documentId}`, 'PATCH',{data: docBody})
}

export async function deleteDocumentModel (documentId: string) {
    return getService(`${process.env.VUE_APP_DOCUMENTSAPI}/documents/${documentId}`, 'DELETE')
}
